import Vue from 'vue';
import axios from 'axios';
import LoadScript from 'vue-plugin-load-script';
import { sync } from 'vuex-router-sync';
import skeleton from 'tb-skeleton';
import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';
import helpers from './helpers';
import VueSweetalert2 from 'vue-sweetalert2';

import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

Vue.use(LoadScript);
Vue.use(skeleton);

const options = {
  customClass: {
    closeButton: 'dialog-close',
    cancelButton: 'dialog-cancel',
    confirmButton: 'dialog-confirm',
    //content: '...',
    //htmlContainer: '...',
  }
};
Vue.use(VueSweetalert2, options);

sync(store, router);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://api.my.ug.edu.ge';

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.headers.common['Content-Language'] = i18n.locale;
const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
}

Vue.loadScript('/static/vendor/jQuery/jquery-3.5.1.min.js').then(() => {
  Vue.loadScript('/static/vendor/popper.min.js').then(() => {
    Vue.loadScript('/static/vendor/bootstrap-4.5.3-dist/js/bootstrap.min.js');
    Vue.loadScript('/static/js/script.js');
    Vue.loadScript('/static/js/select.js');
  });
});

Vue.prototype.helpers = helpers;

 


import { datadogRum } from '@datadog/browser-rum'; 

datadogRum.init({
    applicationId: 'e0230c87-b082-4e2a-9d30-6421e264ec2b',
    clientToken: 'pub7f8b371f2205b518ee7230a851d6d051',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'my.ug',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    allowedTracingUrls: ["https://api.my.ug.edu.ge", /https:\/\/.*\.api.my.ug.edu\.ge/, (url) => url.startsWith("https://api.my.ug.edu.ge")],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackUserInteractions: true,
});




new Vue({
  el: '#app',
  functional: true,
  router,
  store,
  i18n,
  render(h) {
    return h(App);
  },
});
